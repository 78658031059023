import React from "react";
import classes from "./EndChatButton.module.css";
import useChatbot from "../../../Hooks/useChatbot";
import useWebChat from "../../../Hooks/useWebChat"; 
import { useLanguage } from "../../Wizard/langugeContext";

function EndChatButton({ isDisabled }) {
    const { setShowWelcomScreen } = useChatbot(); 
    const { resetWebchat } = useWebChat();
    const { currentLanguage } = useLanguage();

    const handleEndChat = () => {
        if (!isDisabled) {
            resetWebchat();
            setShowWelcomScreen(true); 
        }
    };

    const message = currentLanguage === "en" ? " End Chat" : "Terminar chat";

    return (
        <button
            className={classes.endChatButton}
            onClick={handleEndChat}
            disabled={isDisabled} 
        >
           {message}
        </button>
    );
}

export default EndChatButton;
