import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import Panel from "../../UiKit/Panel/Panel";
import ExpansionToggle from "../../UiKit/Wizard/Expansion/Expansion";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import styles from "../../UiKit/Wizard/WizardLayout/WizardLayout.module.css";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";
import useChatbot from "../../Hooks/useChatbot";

function ResultsPage({sendLoggingEventToBackend}) {
  const { restart, getCurrentOffer, close, householdMemberType } =
    useAffordabilityTool();
  const currentOffer = getCurrentOffer();
  const { closeAffordabilityTool } = useChatbot();

  const { currentLanguage } = useLanguage();
  const resultTranslations = translations[currentLanguage]["ResultsPage"];

  const AFFORDABLE_RESULT_HEADING =
  householdMemberType === "Employee"
    ? `${resultTranslations.affordableResults.employeeHeading}<br>${resultTranslations.affordableResults.affordable}`
    : `${resultTranslations.affordableResults.familyHeading}<br>${resultTranslations.affordableResults.affordable}`;

  // const UNAFFORDABLE_RESULT_HEADING =
  //   householdMemberType === "Employee"
  //     ? "Employee-only coverage is<br>Not Affordable."
  //     : "Family coverage is<br>Not Affordable.";

  const UNAFFORDABLE_RESULT_HEADING =
  householdMemberType === "Employee"
    ? `${resultTranslations.affordableResults.employeeHeading}<br>${resultTranslations.affordableResults.notAffordable}`
    : `${resultTranslations.affordableResults.familyHeading}<br>${resultTranslations.affordableResults.notAffordable}`;

  const COVERAGE_COST_MESSAGE = `${resultTranslations.coverageCost} ${currentOffer.contributionPercentageOfIncome}% ${resultTranslations.ofIncome}`;
  const MAY_QUALIFY_MESSAGE = resultTranslations.mayQualify;
  const MAY_NOT_QUALIFY_MESSAGE = resultTranslations.mayNotQualify;

  const enrollLinkStyle = {
    color: "var(--CalHEERS-Main-Blue, #2D77A3)",
    fontFamily: '"Open Sans"',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    textDecorationLine: "underline",
  };

  const AFFORDABLE_DETAIL_MESSAGE = (
    <span>
      {resultTranslations.affordableResults.detailMessage.affordable}{" "}
      <a
        href="https://apply.coveredca.com/static/lw-web/login"
        target="_blank"
        style={enrollLinkStyle}
      >
        {resultTranslations.enroll}
      </a>
      .
    </span>
  );

  const UNAFFORDABLE_DETAIL_MESSAGE = (
    <span>
      {resultTranslations.affordableResults.detailMessage.notAffordable}{" "}
      <a
        href="https://apply.coveredca.com/static/lw-web/login"
        target="_blank"
        style={enrollLinkStyle}
      >
        {resultTranslations.enroll}
      </a>
      .
    </span>
  );

  const handleCheckAnotherOffer = () => {
    sendLoggingEventToBackend({
      intentName:
        currentLanguage === "en"
          ? "affordabilityToolStartOver"
          : "affordabilityToolStartOverSpanish",
      text:
        currentLanguage === "en"
          ? "Affordability Tool Start Over"
          : "Affordability Tool Start Over Spanish",
    });
    restart();
  }

  return (
    <>
      <WizardLayout.Content>
        <PageLayout>
          <PageLayout.Header>
            <SubHeader subHeader={resultTranslations.subHeader} />
          </PageLayout.Header>
          <PageLayout.Content className="wizard__content">
            <div style={{ marginTop: "32px", paddingBottom: "36px" }}>
              <Panel>
                <Panel.Header>
                  {currentOffer.isAffordable ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: AFFORDABLE_RESULT_HEADING,
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: UNAFFORDABLE_RESULT_HEADING,
                      }}
                    />
                  )}
                </Panel.Header>

                <Panel.Content>
                  <div style={{ lineHeight: "21px", fontSize: "14px" }}>
                    {COVERAGE_COST_MESSAGE}
                  </div>
                  {currentOffer.isAffordable ? (
                    <div
                      style={{
                        lineHeight: "21px",
                        color: "#E22E3F",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                    >
                      {MAY_NOT_QUALIFY_MESSAGE}
                    </div>
                  ) : (
                    <div
                      style={{
                        lineHeight: "21px",
                        color: "#1E824C",
                        fontWeight: "bold",
                        fontSize: "14px",
                        marginBottom: "10px",
                      }}
                    >
                      {MAY_QUALIFY_MESSAGE}
                    </div>
                  )}
                  <ExpansionToggle>
                    <ExpansionToggle.Header>
                      {/* More Details */}
                    </ExpansionToggle.Header>
                    <ExpansionToggle.Content>
                      <div>
                        {currentOffer.isAffordable
                          ? AFFORDABLE_DETAIL_MESSAGE
                          : UNAFFORDABLE_DETAIL_MESSAGE}
                      </div>
                    </ExpansionToggle.Content>
                  </ExpansionToggle>
                </Panel.Content>
              </Panel>
            </div>
          </PageLayout.Content>
        </PageLayout>
      </WizardLayout.Content>
      {/* <div style={{ position: "relative" }}>
        <div className={`${styles.overlaybottom}`}></div>
      </div> */}
      <WizardLayout.Navigation>
        <NavigationButton onClick={() => handleCheckAnotherOffer()} type="primary">
          {resultTranslations.checkAnotherOfferButton}
        </NavigationButton>
        <NavigationButton onClick={() => closeAffordabilityTool()} type="secondary">
          {resultTranslations.closeButton}
        </NavigationButton>
      </WizardLayout.Navigation>
    </>
  );
}

export default ResultsPage;
