import React from "react";
import FooterLayout from "./FooterLayout";
import FooterLanguageOptions from "./FooterLanguageOptions/FooterLanguageOptions";
import EndChatButton from "./EndChatButton";

function Footer({ isHomeScreen }) {
    return (
        <FooterLayout>
            <FooterLayout.Footer>
                <EndChatButton isDisabled={isHomeScreen} />
                <FooterLanguageOptions />
            </FooterLayout.Footer>
        </FooterLayout>
    );
}

export default Footer;
