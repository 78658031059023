import React, { useState } from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import checkMark from "../../Images/affordability-tool-intro-page-checkmark.svg";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import Body from "../../UiKit/Wizard/Body/Body";
import Tooltip from "../../UiKit/Wizard/Tooltip/Tooltip";
import { translations } from "./translations";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";

function WelcomePage({sendLoggingEventToBackend}) {
  const { next, previous } = useAffordabilityTool();
  const [showTooltip, setShowTooltip] = useState(true);
  const { currentLanguage } = useLanguage();
  const welcomePageTranslations = translations[currentLanguage]['WelcomePage']; 
  const navButtons = translations[currentLanguage]['NavButton'];
  const tooltipTranslations = translations[currentLanguage]['WelcomePage'].tooltip;

  const handleNext = () => {
    const intentName = currentLanguage === "en" ? "AffordabilityTool-Start" : "AffordabilityTool-Start-Spanish";
    const text = "User clicked on 'Get Started' button";
    sendLoggingEventToBackend({
      intentName: intentName,
      text: text,
    })
    next();

  };
  
  return (
    <PageLayout>
      <PageLayout.Content>
        <Content showTooltip={showTooltip} currentLanguage={currentLanguage} welcomePageTranslations={welcomePageTranslations} tooltipTranslations={tooltipTranslations}/>
      </PageLayout.Content>
      <PageLayout.Navigation>
        <NavigationButton onClick={handleNext} type="primary">
          {navButtons.nextButton}
        </NavigationButton>
        <NavigationButton onClick={previous} type="secondary">
          {navButtons.backButton}
        </NavigationButton>
      </PageLayout.Navigation>
    </PageLayout>
  );
}

function Content({ showTooltip, currentLanguage, welcomePageTranslations, tooltipTranslations }) {
 
  return (
    <div>
      <SubHeader
        style={{ display: "flex", marginBottom: "24px", marginTop: "0px" }}
        subHeader={welcomePageTranslations.subHeader}
      />
      <Body
        bodyText={
          <>
         {/* <Body
              bodyText="Before you get started:"
              style={{  marginBottom: '16px', marginTop: "-22px" }}
            /> */}
            <ul>
              <li
                style={{
                  color: "var(--CalHEERS-Primary-Text, #334048)",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "150%",
                }}
                id="bullet1"
              >
                <Body
                  bodyText={
                    <>
                     { welcomePageTranslations.bodyText.first}
                      {showTooltip && (
                        <Tooltip
                          width="200px"
                          height="auto"
                          message={tooltipTranslations.message1}
                          forcePosition="right"
                          forcePositionSmall={currentLanguage === 'es' ? "bottom" : "bottom"}
                          forcePositionLeft="bottom"
                          forcePositionRight="bottom"
                        />
                      )}
                    </>
                  }
                />
              </li>
              <li>
                <Body
                  bodyText={
                    <>
                     {welcomePageTranslations.bodyText.second}
                      {showTooltip && (
                        <Tooltip
                          width="178px"
                          height="auto"
                          message={tooltipTranslations.message2}
                          forcePosition="left"
                          forcePositionSmall={currentLanguage === 'es' ? "left" : "right"}
                          forcePositionLeft="left"
                          forcePositionRight={currentLanguage === 'es' ? "left" : "left"}
                        />
                      )}
                    </>
                  }
                />
              </li>
              <li>
                <Body
                  bodyText={
                    <>
                      {welcomePageTranslations.bodyText.third}
                    </>
                  }
                />
              </li>
            </ul>
            <div style={{ marginTop: "10px" }}>
              {welcomePageTranslations.bodyText.fourth}{" "}
              <a href={welcomePageTranslations.link} target="_blank" rel="noopener noreferrer" style={{
                  color: "var(--CalHEERS-Main-Blue, #2D77A3)",
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "150%",
                  textDecoration:"none"
                }}>
                {welcomePageTranslations.bodyText.fifth}
              </a>
            </div>
          </>
        }
      />
    </div>
  );
}

export default WelcomePage;
