import React from "react";
import PageLayout from "../../UiKit/Wizard/Page/PageLayout";
import SubHeader from "../../UiKit/Wizard/SubHeader/SubHeader";
import NavigationButton from "../../UiKit/Wizard/NavigationButton/NavigationButton";
import useAffordabilityTool from "../../Hooks/useAffordabilityTool";
import Body from "../../UiKit/Wizard/Body/Body";
import Edit from "../../UiKit/Wizard/Edit/Edit";
import useDollarAmountFormatter from "../../Utilities/getFormattedDollarAmount";
import WizardLayout from "../../UiKit/Wizard/WizardLayout/WizardLayout";
import { useLanguage } from "../../UiKit/Wizard/langugeContext";
import { translations } from "./translations";

function ReviewAndConfirmPage({ sendLoggingEventToBackend }) {
    const {
        name, next, previous, coverageYear, annualHouseholdIncome, isEmployee, addCurrentOffer, contributionAmount, contributionFrequency, setCurrentPage, householdMemberType, householdMember
    } = useAffordabilityTool();
    const { getFormattedDollarAmount } = useDollarAmountFormatter();

    const { currentLanguage } = useLanguage();
    const ConfirmTranslations = translations[currentLanguage]["ReviewAndConfirmPage"];
    const NavButton = translations[currentLanguage]["NavButton"];

    const onNextClick = () => {
        const intentName = currentLanguage === "en" ? (coverageYear === "2024" ? "affordabilityToolResult2024" : "affordabilityToolResult2023") : (coverageYear === "2024" ? "affordabilityToolResult2024Spanish" : "affordabilityToolResult2023Spanish");
        const text = currentLanguage === "en" ? "Affordability Tool Result" : "Affordability Tool Result Spanish";
        sendLoggingEventToBackend({ intentName: intentName, text: text });
        addCurrentOffer();
        next();
    };

    const handleEditClick = (GoToScreen) => {
        setCurrentPage(GoToScreen);
    };

    const employeeType = currentLanguage === 'es' ? 'Empleado' : 'Employee';
    const familyType = currentLanguage === 'es' ? 'Miembro de la familia' : 'fFamily Member';

    return (
        <>
            <WizardLayout.Content>
                <PageLayout>
                    <PageLayout.Header>
                        <SubHeader style={{ marginBottom: "" }} subHeader={ConfirmTranslations.subHeader} />
                    </PageLayout.Header>
                    <PageLayout.Content className="wizard__content">
                        <div style={{ marginBottom: "32px", marginTop: "8px" }}></div>

                        <div style={{ display: "flex", marginBottom: "20px" }}>
                            <div style={{ maxWidth: "220px", lineHeight: "20px" }}>
                                <div>
                                    <strong>{ConfirmTranslations.householdMember}</strong>
                                </div>
                                {householdMemberType}
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <Edit onClick={() => handleEditClick(3)}>{ConfirmTranslations.edit}</Edit>
                            </div>
                        </div>

                        <div style={{ display: "flex", marginBottom: "20px" }}>
                            <div style={{ maxWidth: "220px", lineHeight: "20px" }}>
                                <div>
                                    <strong>{ConfirmTranslations.coverageYear}</strong>
                                </div>
                                {coverageYear}
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <Edit onClick={() => handleEditClick(4)}>{ConfirmTranslations.edit}</Edit>
                            </div>
                        </div>

                        <div style={{ display: "flex", marginBottom: "20px" }}>
                            <div style={{ maxWidth: "220px", lineHeight: "20px" }}>
                                <div>
                                    <strong>{ConfirmTranslations.annualHouseholdIncome} {coverageYear}</strong>
                                </div>
                                ${getFormattedDollarAmount(annualHouseholdIncome)}
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <Edit onClick={() => handleEditClick(5)}>{ConfirmTranslations.edit}</Edit>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginBottom: "20px" }}>
                            <div style={{ maxWidth: "220px", lineHeight: "20px" }}>
                                <div>
                                    <strong>{ConfirmTranslations.contributionAmount}</strong>
                                </div>
                                <p>${contributionAmount} {householdMemberType === employeeType ? ConfirmTranslations.employee : ConfirmTranslations.family}</p>
                                <p>{ConfirmTranslations.paid} {contributionFrequency}</p>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <Edit onClick={() => handleEditClick(6)}>{ConfirmTranslations.edit}</Edit>
                            </div>
                        </div>
                    </PageLayout.Content>
                </PageLayout>
            </WizardLayout.Content>
            {/* <div style={{ "position": "relative"}}>
                    <div className={`${styles.overlaybottom}`}></div>
                </div> */}
            <WizardLayout.Navigation>
                <NavigationButton onClick={onNextClick} type={"primary"}>{NavButton.GetMyResultsButton}</NavigationButton>
                <NavigationButton onClick={() => previous()} type={"secondary"}>{NavButton.backButton}</NavigationButton>
            </WizardLayout.Navigation>
        </>
    );
}

export default ReviewAndConfirmPage;
